
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
// const _import = require('./import-' + process.env.NODE_ENV)
const _import = require('./import-production')

export default [
  {
    path: '/WomenBaby',
    component: _import('main'),
    name: 'WomenBaby',
    redirect: { name: 'WomenBabyhome' },
    meta: { title: '主入口整体布局', isTab: false },
    children: [
      // 通过meta对象设置路由展示方式
      // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
      // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
      // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
      {
        path: 'home', component: _import('common/home'), name: 'WomenBabyhome', meta: { title: '首页', isTab: false }, redirect: { name: 'WomenReportWeekly' },
        children: [{ path: 'WomenReportWeekly', component: _import('women_baby/records/Tendency/Tendency_overall'), name: 'WomenReportWeekly', meta: { title: '健康周报', isTab: false } },
      // { path: 'Tendency_overall', component: _import('women_baby/records/Tendency/Tendency_overall'), name: 'Tendency_overall_women', meta: { title: '整体趋势分析', isTab: true } },
        
        ]
      },
      // { path: 'HistoryTem', component: _import('women_baby/records/History/HistoryTem'), name: 'HistoryTem_women', meta: { title: '体温检测记录', isTab: true } },
      // { path: 'HistoryBaby', component: _import('women_baby/records/History/HistoryBaby'), name: 'HistoryBaby_women', meta: { title: '胎心仪检测记录', isTab: true } },
      // { path: 'HistoryBaby2', component: _import('women_baby/records/History/HistoryBaby2'), name: 'HistoryBaby2_women', meta: { title: '胎心仪检测内容', isTab: true } },
      { path: 'myh_history', component: _import('women_baby/records/myh_history'), name: 'myh_history_women', meta: { title: '检测项目/记录', isTab: true } },
      { path: 'myh_tend', component: _import('women_baby/records/myh_tend'), name: 'myh_tend_women', meta: { title: '总体趋势分析', isTab: true } },
      // {
      //   // path: 'HistoryTab', component: _import('women_baby/records/History/HistoryTab'), name: 'HistoryTab', meta: {title: '历史记录', isTab: true},
      //   // children:[{path: 'test', component: _import('women_baby/records/History/test'), name: 'test', meta: {title: '页面3', isTab: false}}]
      //   path: 'HistorySingo', component: _import('women_baby/records/History/HistorySingo'), name: 'HistorySingo_women', meta: { title: '单导联心电检测记录', isTab: true, showfater: true },
      //   children: [{ path: 'HistorySingo2', component: _import('women_baby/records/History/HistorySingo2'), name: 'HistorySingo2_women', meta: { title: '页面', isTab: false, showfater: false } },
      //   ]
      // },
      // {
      //   path: 'HistorySingoHolter', component: _import('women_baby/records/History/HistorySingoHolter'), name: 'HistorySingoHolter_women', meta: { title: '单导联Holter检测记录', isTab: true, showfater: true },
      //   children: [{ path: 'HistorySingoHolter2', component: _import('women_baby/records/History/HistorySingoHolter2'), name: 'HistorySingoHolter2_women', meta: { title: '页面', isTab: false, showfater: false } },
      //   { path: 'holterReport', component: _import('women_baby/records/holter_report'), name: 'holterReport_women', meta: { title: '动态报告', isTab: false, showfater: false } }]
      // },
      // {
      //   path: 'HistoryEight', component: _import('women_baby/records/History/HistoryEight'), name: 'HistoryEight_women', meta: { title: '八导联心电检测记录', isTab: true, showfater: true },
      //   children: [{ path: 'HistoryEight2', component: _import('women_baby/records/History/HistoryEight2'), name: 'HistoryEight2_women', meta: { title: '页面', isTab: false, showfater: false } }]
      // },
      // {
      //   path: 'HistoryEightHolter', component: _import('women_baby/records/History/HistoryEightHolter'), name: 'HistoryEightHolter_women', meta: { title: '八导联Holter检测记录', isTab: true, showfater: true },
      //   children: [{ path: 'HistoryEightHolter2', component: _import('women_baby/records/History/HistoryEightHolter2'), name: 'HistoryEightHolter2_women', meta: { title: '页面', isTab: false, showfater: false } },
      //   { path: 'EightHolterReport', component: _import('women_baby/records/EightHolterReport'), name: 'EightHolterReport_women', meta: { title: '八导联动态报告', isTab: false, showfater: false } }]
      // },
      // { path: 'HistoryBloodFat', component: _import('women_baby/records/History/HistoryBloodFat'), name: 'HistoryBloodFat_women', meta: { title: '血脂检测记录', isTab: true } },
      // { path: 'HistoryBloodGlucose', component: _import('women_baby/records/History/HistoryBloodGlucose'), name: 'HistoryBloodGlucose_women', meta: { title: '血糖检测记录', isTab: true } },
      // { path: 'HistoryBloodOxygen', component: _import('women_baby/records/History/HistoryBloodOxygen'), name: 'HistoryBloodOxygen_women', meta: { title: '血氧检测记录', isTab: true } },
      // { path: 'HistoryBloodPressure', component: _import('women_baby/records/History/HistoryBloodPressure'), name: 'HistoryBloodPressure_women', meta: { title: '血压检测记录', isTab: true } },
      // { path: 'HistoryBaby', component: _import('women_baby/records/History/HistoryBaby'), name: 'HistoryBaby_women', meta: { title: '胎心仪检测记录', isTab: true } },
      // { path: 'HistoryBaby2', component: _import('women_baby/records/History/HistoryBaby2'), name: 'HistoryBaby2_women', meta: { title: '胎心仪检测内容', isTab: true } },
      // { path: 'HistoryBio', component: _import('women_baby/records/History/HistoryBio'), name: 'HistoryBio_women', meta: { title: '生化分析仪检测记录', isTab: true } },
      // { path: 'HistoryTem', component: _import('women_baby/records/History/HistoryTem'), name: 'HistoryTem_women', meta: { title: '体温检测记录', isTab: true } },
      // { path: 'HistoryBodyFat', component: _import('women_baby/records/History/HistoryBodyFat'), name: 'HistoryBodyFat_women', meta: { title: '体脂检测记录', isTab: true } },
      // { path: 'HistoryThreeInOne', component: _import('women_baby/records/History/HistoryThreeInOne'), name: 'HistoryThreeInOne_women', meta: { title: '三合一检测记录', isTab: true } },
      // { path: 'HistoryUrine', component: _import('women_baby/records/History/HistoryUrine'), name: 'HistoryUrine_women', meta: { title: '尿液检测记录', isTab: true } },

      // { path: 'Tendency_singo', component: _import('women_baby/records/Tendency/Tendency_singo'), name: 'Tendency_singo_women', meta: { title: '单导联心电趋势分析', isTab: true } },
      // { path: 'Tendency_singoHolter', component: _import('women_baby/records/Tendency/Tendency_singoHolter'), name: 'Tendency_singoHolter_women', meta: { title: '单导联Holter趋势分析', isTab: true } },
      { path: 'Tendency_overall', component: _import('women_baby/records/Tendency/Tendency_overall'), name: 'Tendency_overall_women', meta: { title: '整体趋势分析', isTab: true } },

      
      { path: 'ExamReport', component: _import('women_baby/records/report'), name: 'ExamReport_women', meta: { title: '综合体检报告', isTab: true } },

      { path: 'ECG_consult', component: _import('women_baby/consultant/ECG_consult'), name: 'ECG_consult_women', meta: { title: '心电咨询', isTab: true } },
      { path: 'RemoteDiagnosis', component: _import('women_baby/consultant/remote_diagnosis'), name: 'RemoteDiagnosis_women', meta: { title: '远程诊疗', isTab: true } },
      { path: 'PsInfo', component: _import('women_baby/ps_info/ps_information'), name: 'PsInfo_women', meta: { title: '个人信息表', isTab: true } },
      { path: 'RelativeManage', component: _import('women_baby/relative/relatives_manage'), name: 'RelativeManage_women', meta: { title: '亲友管理', isTab: true } },
      { path: 'AccountSetting', component: _import('women_baby/setting/account_setting'), name: 'AccountSetting_women', meta: { title: '账户设置', isTab: true } },
      { path: 'Appointment', component: _import('women_baby/remote/appointment'), name: 'Appointment_women', meta: { title: '诊疗预约', isTab: true } },
      { path: 'RemoteHistory', component: _import('women_baby/remote/remoteHistory'), name: 'RemoteHistory_women', meta: { title: '诊疗记录', isTab: true } },
      { path: 'emergency3', component: _import('women_baby/remote/emergency_treatment3'), name: 'emergency3_women', meta: { title: '急诊', isTab: true } },
      { path: 'reportUpload', component: _import('women_baby/remote/reportUpload'), name: 'reportUpload_women_women', meta: { title: '报告上传', isTab: true } },
      { path: 'ViewTreatReport', component: _import('women_baby/remote/ViewTreatReport'), name: 'ViewTreatReport_women', meta: { title: '诊疗报告', isTab: true } },

      { path: 'pregnant_tips', component: _import('women_baby/tips/pregnant_tips'), name: 'pregnant_tips', meta: { title: '孕期知识', isTab: true } },

      { path: 'memberData', component: _import('women_baby/doctor/memberData'), name: 'memberData_women', meta: { title: '成员管理', isTab: true } },

      // { path: '/theme', component: _import('common/theme'), name: 'theme', meta: { title: '主题' } },
    ],
  }
]